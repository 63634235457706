import { useState, useEffect, useCallback } from "react";
import { BigNumber, Contract, ethers, providers } from "ethers";
import Web3Modal from "web3modal";
import { getNetworkForMetamask, networkConfig } from "../config/network";
import { toHex } from "../helpers/utils";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {
  moralisApiKey,
  foodContractAddress,
  soulZContractAddress,
  chainName,
  supportedChainID,
  InfuraId,
  morilisURL,
} from "../config/constant";
import { getInfuraUrl } from "../helpers/utils";
import "../styles/Global";
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import erc721Abi from "../contracts/abi/ERC_721.json";
import erc1155Abi from "../contracts/abi/ERC_1155.json";
export const networkOptions = Object.values(networkConfig);
import axios from "axios";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: InfuraId, //process.env.NEXT_PUBLIC_INFURA_ID, // required
      rpc: {
        1: "https://mainnet.infura.io/v3/",
      },
    },
  },
};

export const useWalletModal = () => {
  const hasWindow = typeof window !== "undefined";
  const [web3Modal, setWeb3Modal] = useState<Web3Modal | undefined>();
  useEffect(() => {
    if (hasWindow && web3Modal == null) {
      setWeb3Modal(
        new Web3Modal({
          network: "mainnet",
          cacheProvider: true, // optional
          providerOptions, // required
        })
      );
    }
    //eslint-disable-next-line
  }, [hasWindow]);

  return web3Modal;
};

export const useWalletConnect = () => {
  const [provider, setProvider] = useState<
    providers.Web3Provider | undefined
  >();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [noClaimModelStatus, setNoClaimModelStatus] = useState<boolean>(false);
  const [account, setAccount] = useState<string | undefined>("");
  const [transactionHash, setTransactionHash] = useState<string | undefined>();
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState<number | undefined>();
  const [network, setNetwork] = useState<number | undefined>(8001);
  const [signer, setSigner] = useState<JsonRpcSigner | undefined>();
  const [nftCount, setNFTCount] = useState<number | undefined>();
  const [nftArry, setNftsArray] = useState<any>([]);
  const [claimableNftStatus, setClaimableNftStatus] = useState<boolean>(false);
  const [transactionStatus, setTransactionStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean | false>(false);
  const [transactionFailed, setTransactionFailed] = useState<string>("");
  const [soulZWalletNfts, setSoulZwalletNfts] = useState<any>();
  const [searchNft, setSearchNft] = useState<any>();
  const [searchLoader, setSearchLoader] = useState<boolean>(false);
  const [tokenIdSearch, setTokenIdSearch] = useState<boolean>(false);

  const web3Modal: Web3Modal | undefined = useWalletModal();
  var nftsOwnerArray: any = [];
  let modalProvider: any;
  const connectWallet = useCallback(async () => {
    if (web3Modal == null) {
      return;
    }
    try {
      setSearchLoader(true);
      modalProvider = await web3Modal.connect();
      const web3provider = new ethers.providers.Web3Provider(modalProvider);
      const accounts = await web3provider.listAccounts();
      const providerNetwork = await web3provider.getNetwork();
      const signer = web3provider.getSigner();
      if (accounts) {
        setAccount(accounts[0]);
      }
      setSigner(signer);
      setChainId(providerNetwork.chainId);
      setProvider(web3provider);

      if (providerNetwork.chainId !== supportedChainID) {
        setAccount("");
        const isSwitched = await switchNetwork(supportedChainID);
        if (isSwitched) {
          const web3provider = new ethers.providers.Web3Provider(modalProvider);
          const signer = web3provider.getSigner();
          setProvider(web3provider);
          setSigner(signer);
          setAccount(accounts[0]);
        } else {
          setAccount("");
          await disconnect();
        }
      }
      getOwnerNfts();
      getSoulZWalletNFTs(accounts[0]);
      window.ethereum.on("chainChanged", handleChainChanged);
    } catch (error) {
      setError("Error connecting to wallet.");
      setSearchLoader(false);
    }
    //eslint-disable-next-line
  }, [web3Modal]);

  const showModal = () => {
    setOpenModal(true);
  };

  const modalClose = () => {
    setOpenModal(false);
    setTransactionStatus(false);
    setNoClaimModelStatus(false);
    setTransactionFailed("");
  };
  const modalCloseDisconnect = () => {
    setOpenModal(false);
    disconnect();
  };

  var totalNFTs: number = 0;
  var nextPageParam: any = null;

  //getting All soulZ Nfts
  const getSoulZWalletNFTs = async (account: any) => {
    setSearchLoader(true);
    await axios
      .get(
        `/api/external?account=${account}&nextPageParam=${nextPageParam}&contractAddress=${soulZContractAddress}`
      )
      .then(function (response) {
        totalNFTs = response?.data?.data?.result.length;
        nextPageParam = response?.data?.data?.cursor;
        //first api call records

        if (nftsOwnerArray.length === 0) {
          for (let j = 0; j < response.data?.data?.result.length; j++) {
            nftsOwnerArray.push(
              parseInt(response.data?.data?.result[j]?.token_id, 10)
            );
          }
        }
      })
      .catch(function (error) {
        console.error(error);
      });
    if (totalNFTs <= 100) {
      setNftsArray(nftsOwnerArray);
    }
    if (totalNFTs === 0) {
      setSearchLoader(false);
    }
    if (totalNFTs > 100) {
      let loopNumber = Math.floor((totalNFTs - 100) / 100);
      for (let i = 0; i < loopNumber; i++) {
        await axios
          .get(
            `/api/external?account=${account}&nextPageParam=${nextPageParam}&contractAddress=${soulZContractAddress}`
          )
          .then(function (responseInside) {
            nextPageParam = responseInside?.data?.data?.cursor;
            for (let j = 0; j < responseInside.data?.data?.result.length; j++) {
              nftsOwnerArray.push(
                parseInt(responseInside.data?.data?.result[j]?.token_id, 10)
              );
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      }
      setNftsArray(nftsOwnerArray);
    }
  };
  const switchNetwork = useCallback(
    async (network?: number) => {
      if (network == null) {
        return;
      }
      try {
        // @ts-ignore
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: toHex(network) }],
        });
        return true;
      } catch (switchError: any) {
        if ((switchError as any).code === 4001) {
          return false;
        }
        if ((switchError as any).code === 4902) {
          try {
            // @ts-ignore
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [getNetworkForMetamask(networkConfig[toHex(network)])],
            });
            // connectWallet();
          } catch (error) {
            if ((error as any).code === 4001) {
              return false;
            }
            setError("Error switching wallet.");
          }
        }
      }
    },
    //eslint-disable-next-line
    [provider]
  );

  const getOwnerNfts = async () => {
    if (provider) {
      const contract = new ethers.Contract(
        soulZContractAddress!,
        erc721Abi,
        signer
      );
      try {
        const response = await contract.balanceOf(account);
        setNFTCount(response._hex);
      } catch (err) {}
    } else {
      setError("Please connect wallet");
    }
  };

  useEffect(() => {
    if (nftArry.length !== 0 && account) {
      CheckWalletNftmintStatus(nftArry);
    }
  }, [account, nftArry, loading]);
  const CheckWalletNftmintStatus = async (nftArry: any) => {
    setSearchLoader(true);
    const RPC = getInfuraUrl(supportedChainID);
    const provider = new ethers.providers.JsonRpcProvider(RPC);
    const contract = new ethers.Contract(
      foodContractAddress!,
      erc1155Abi,
      provider
    );
    try {
      let checkMint = await contract.getTokenIdsAlreadyClaimed(nftArry);
      var keys: any = checkMint[0];
      var values: any = checkMint[1];
      var searchNftStatus: any = {};
      keys.forEach((key: any, i: any) => {
        searchNftStatus[parseInt(key._hex)] = values[i];
      });
      if (searchNftStatus.length !== 0) {
        setSoulZwalletNfts(searchNftStatus);
        setSearchLoader(false);
      } else {
        setSoulZwalletNfts(undefined);
        setSearchLoader(false);
      }
    } catch {
      setSearchLoader(false);
    }
  };

  const mintNfts = async () => {
    setLoading(true);
    const chunkSize = 10;
    let chunk;
    let claimableNftsArr: any = [];
    let checkMint;
    if (provider) {
      const contract = new ethers.Contract(
        foodContractAddress!,
        erc1155Abi,
        signer
      );
      //check status of nft is claimable or not e.g true=claimed false=claimable
      checkMint = await contract.getTokenIdsAlreadyClaimed(nftArry);
      var keys: any = checkMint[0];
      var values: any = checkMint[1];
      var result: any = {};
      keys.forEach((key: any, i: any) => {
        result[parseInt(key._hex)] = values[i];
      });
      //getting all the claimable Nfts tokenIds

      if (result.length !== 0) {
        for (const item in result) {
          if (result[item] === false) {
            claimableNftsArr.push(parseInt(item));
          }
        }
      }
    }
    if (claimableNftsArr.length === 0) {
      setClaimableNftStatus(true);
      setLoading(false);
      setNoClaimModelStatus(true);
      setTransactionFailed("All NFTs claimed");
    } else {
      if (provider) {
        const contract = new ethers.Contract(
          foodContractAddress!,
          erc1155Abi,
          signer
        );
        if (chainId !== supportedChainID) {
          switchNetwork(supportedChainID);
        }
        try {
          //makes chunks of nfts tokenIds
          if (claimableNftsArr.length !== 0) {
            chunk = await claimableNftsArr.slice(0, chunkSize);
          }
          let estimatedGasLimit = await contract.estimateGas.claim(chunk);
          estimatedGasLimit = estimatedGasLimit
            .mul(BigNumber.from(10000).add(BigNumber.from(3000)))
            .div(BigNumber.from(10000));
          const response = await contract.claim(chunk, {
            gasLimit: estimatedGasLimit,
          });
          response
            .wait()
            .then((success: any) => {
              if (success.confirmations && success.transactionHash) {
                setTransactionStatus(true);
                setTransactionHash(success.transactionHash);
                setLoading(false);
                setTransactionFailed("");
                setNoClaimModelStatus(false);
              }
            })
            .catch((err: any) => {
              setNoClaimModelStatus(true);
              setTransactionFailed("Transaction failed!");
              setLoading(false);
            });
        } catch (err) {
          setNoClaimModelStatus(true);
          setTransactionFailed("User rejected!");
          setLoading(false);
          setError("User rejected transaction!");
        }
      } else {
        setError("Error! Connect wallet first");
      }
    }
  };
  const getTokenIdsAlreadyClaimed = async (tokenId: number) => {
    setTokenIdSearch(true);
    const RPC = getInfuraUrl(supportedChainID);
    const provider = new ethers.providers.JsonRpcProvider(RPC);
    const contract = new ethers.Contract(
      foodContractAddress!,
      erc1155Abi,
      provider
    );
    try {
      let checkMint = await contract.getTokenIdsAlreadyClaimed([tokenId]);
      var keys: any = checkMint[0];
      var values: any = checkMint[1];
      var searchNftStatus: any = {};
      keys.forEach((key: any, i: any) => {
        searchNftStatus[parseInt(key._hex)] = values[i];
      });
      if (searchNftStatus.length !== 0) {
        setSearchNft(searchNftStatus);
        setTokenIdSearch(false);
      } else {
        setSearchNft(undefined);
        setTokenIdSearch(false);
      }
    } catch {
      setTokenIdSearch(false);
    }
  };
  const refreshState = async () => {
    setAccount("");
    setChainId(undefined);
    setNetwork(undefined);
    setNftsArray([]);
    setProvider(undefined);
    setSigner(undefined);
    setSearchNft(undefined);
    setSoulZwalletNfts(undefined);
  };

  const disconnect = useCallback(async () => {
    if (web3Modal == null) {
      return;
    }
    setClaimableNftStatus(false);
    await web3Modal.clearCachedProvider();
    window.localStorage.clear();
    modalProvider = null;
    await refreshState();
  }, [web3Modal]);

  useEffect(() => {
    handleAccountChange();
    //eslint-disable-next-line
  }, [account]);

  const handleAccountChange = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async () => {
        const accounts = await provider?.listAccounts();
        if (accounts) {
          setAccount(accounts[0]);
          window.location.reload();
          disconnect();
        }
      });
    }
  };
  const handleChainChanged = async (_hexChainId: number | undefined) => {
    setChainId(Number(_hexChainId));
    if (supportedChainID !== Number(_hexChainId)) {
      disconnect();
    }
  };
  return {
    provider,
    account,
    network,
    loading,
    chainId,
    connectWallet,
    disconnect,
    switchNetwork,
    setNetwork,
    tokenIdSearch,
    web3Modal,
    searchLoader,
    openModal,
    signer,
    searchNft,
    soulZWalletNfts,
    nftArry,
    transactionFailed,
    claimableNftStatus,
    transactionStatus,
    transactionHash,
    noClaimModelStatus,
    mintNfts,
    setSearchNft,
    showModal,
    modalClose,
    modalCloseDisconnect,
    getTokenIdsAlreadyClaimed,
  };
};
