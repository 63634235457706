import React, { Dispatch, SetStateAction } from "react";
import Web3Modal from "web3modal";
import { useWalletConnect } from "../hooks/useWalletConnect";
import { providers } from "ethers";
import { JsonRpcSigner } from "@ethersproject/providers";

type WalletContextProviderPropsType = {
  children: React.ReactElement;
};

export type WalletContextProviderType = {
  provider: providers.Web3Provider | undefined;
  account: string | undefined;
  network: number | undefined;
  chainId: number | undefined;
  openModal: boolean | false;
  signer: JsonRpcSigner | undefined;
  showModal: () => void;
  nftArry: any;
  soulZWalletNfts: any;
  claimableNftStatus: boolean | false;
  transactionStatus: boolean | false;
  noClaimModelStatus: boolean | false;
  transactionHash: string | undefined;
  loading: boolean | false;
  transactionFailed: string | undefined;
  modalClose: () => void;
  setSearchNft: (searchNft: any) => void;
  searchNft: any;
  tokenIdSearch: boolean | false;
  searchLoader: boolean | false;
  modalCloseDisconnect: () => void;
  connectWallet: () => void;
  getTokenIdsAlreadyClaimed: (tokenId: number) => void;
  disconnect: () => void;
  switchNetwork: (networkId?: number) => void;
  setNetwork: Dispatch<SetStateAction<number | undefined>>;
  mintNfts: () => void;

  web3Modal: Web3Modal | undefined;
};

const WalletContext = React.createContext<WalletContextProviderType>(
  {} as WalletContextProviderType
);

function WalletContextProvider(props: WalletContextProviderPropsType) {
  const wallet = useWalletConnect();

  return (
    <WalletContext.Provider value={{ ...wallet }}>
      {props.children}
    </WalletContext.Provider>
  );
}

export default WalletContextProvider;

export const useWalletContext = (): WalletContextProviderType => {
  const walletContext = React.useContext(WalletContext);
  if (Object.keys(walletContext).length === 0) {
    throw new Error(`Missing WalletContext provider`);
  }
  return walletContext;
};
