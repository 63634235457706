import { NEXT_PUBLIC_ORBZ_CONTRACT_ADDRESS } from "./env";
export const supportedChainID =
  parseInt(`${process.env.NEXT_PUBLIC_SUPPORTED_CHAIN_ID}`) || 43113;
export const InfuraURL: Record<number, string> = {
  1: `https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_ID}`,
  5: `https://goerli.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_ID}`,
  43113: `https://avalanche-fuji.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_ID}`,
};
export const EtherScanUrl: Record<number, string> = {
  1: "https://etherscan.io/tx/",
  5: "https://goerli.etherscan.io/tx/",
  43113: "https://testnet.snowtrace.io/tx/",
};
export const morilisURL = "https://deep-index.moralis.io/api/v2/";
export const pinataURL = process.env.NEXT_PUBLIC_IPFS_GATEWAY;
export const ascendetContractAddress =
  process.env.NEXT_PUBLIC_ASCENDENT_CONTRACT_ADDRESS || "";
export const foodContractAddress =
  process.env.NEXT_PUBLIC_FOOD_CONTRACT_ADDRESS;
export const soulZContractAddress =
  process.env.NEXT_PUBLIC_SOULZ_CONTRACT_ADDRESS;
export const orbzContractAddress =
  process.env.NEXT_PUBLIC_ORBZ_CONTRACT_ADDRESS;
export const tokenAddress = process.env.NEXT_PUBLIC_TOKEN_ADDRESS;
export const moralisApiKey = process.env.MORALIS_API_KEY;
export const InfuraId = process.env.NEXT_PUBLIC_INFURA_ID;

// Possible values for chainName are 0xa869, eth
export const chainName =
  process.env.NEXT_PUBLIC_SUPPORTED_CHAIN_NAME || "0xa869";

export const Traits = {
  Backgrounds: "Backgrounds",
  Dresses: "Dresses",
  Face: "Face",
  Halo: "Halo",
  LongHair: "Long Hair",
  Scepter: "Scepter",
  ShortHair: "Short Hair",
  SkinTone: "Skin Tone",
  Wings: "Wings",
  BodyVariation: "Body Variation",
  Clothes: "Clothes",
  Eyes: "Eyes",
  HalfShoulderVariation: "Half Shoulder Variation",
  HeadMutation: "Head Mutation",
  Lips: "Lips",
  Tales: "Tales",
  Earrings: "Earrings",
  Suit: "Suit",
  Accompany: "Accompany",
  ElementalHair: "Elemental Hair",
  Hair: "Hair",
  Headgear: "Headgear",
  Powersource: "Powersource",
  Weapons: "Weapons",
  Hands: "Hands",
  Aura: "Aura",
  AuraF: "AuraF",
  Special: "Special",
  SpecialF: "SpecialF",
  Galaxy: "Galaxy",
  Nature: "Nature",
  VineSkull: "Vine Skull",
  Hellfire: "Hellfire",
  SoraYamakiF: "Sora Yamaki F",
  SoraYamakiM: "Sora Yamaki M",
};

export const Skin = {
  Elf: "Elf",
  Angel: "Angel",
  CyborgFemale: "CyborgFemale",
  CyborgMale: "CyborgMale",
  Demon: "Demon",
  Beast: "Beast",
};

export const Angel = new Set([
  Traits.Backgrounds,
  Traits.Scepter,
  Traits.Wings,
  Traits.Halo,
  Traits.Dresses,
  Traits.Aura,
]);

export const Beast = new Set([
  Traits.Backgrounds,
  Traits.HeadMutation,
  Traits.Tales,
  Traits.Aura,
  Traits.Special,
]);

export const CyborgMale = new Set([
  Traits.Backgrounds,
  Traits.Powersource,
  Traits.Clothes,
  Traits.Weapons,
  Traits.Aura,
]);

export const CyborgFemale = new Set([
  Traits.Backgrounds,
  Traits.Powersource,
  Traits.Clothes,
  Traits.Weapons,
  Traits.Aura,
]);

export const Demon = new Set([
  Traits.Backgrounds,
  Traits.Wings,
  Traits.Scepter,
  Traits.Suit,
  Traits.Aura,
]);

export const Elf = new Set([
  Traits.Backgrounds,
  Traits.Scepter,
  Traits.Wings,
  Traits.Dresses,
  Traits.Aura,
]);

export const AuraSet = new Set([Traits.Aura, Traits.AuraF]);
export const SpecialSet = new Set([Traits.Special, Traits.SpecialF]);
