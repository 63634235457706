import axios from "axios";
import { ethers } from "ethers";
import React, { createContext, useState, useContext } from "react";
import {
  ascendetContractAddress,
  soulZContractAddress,
} from "../config/constant";
import erc721ascendatAbi from "../contracts/abi/ERC_721_upgradable.json";
import erc721Abi from "../contracts/abi/ERC_721.json";
import { useWalletContext } from "./WalletContext";

interface MintContextData {
  mintAscendantImages: any;
  setMintAscendantImages: (mintAscendantImages: any) => void;
  getAscendantWalletNfts: (account: string) => Promise<void>;
  ascendantNftsArray: any;
  ascendantLoader: boolean;
  hasRemainingAscendants: boolean;
  gettingSoulZWalletNFTs: (account: string) => Promise<void>;
  soulzNftsArray: any;
  traitUpdateObject: any;
  soulZLoader: boolean;
  mintingSuccessState: string;
  setMintingSuccessState: (mintingSuccessState: string) => void;
  setTraitUpdateObject: (traitUpdateObject: any) => void;
  mintingFailureState: string;
  setMintingFailureState: (mintingFailureState: string) => void;
  rerollNftId: any;
  setRerollNftId: (rerollNftId: any) => void;
  orbzBalance: number;
  setRerollNft: (rerollNft: any) => void;
  setOrbzBalance: (orbzBalance: number) => void;
  rerollNft: any;
  setAscendantNftId: (ascendantNftId: string) => void;
  ascendantNftId: string;
}
const MintAscendedContext = createContext<MintContextData>(
  {} as MintContextData
);

export const MintAscendedProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { account, signer } = useWalletContext();
  const [mintAscendantImages, setMintAscendantImages] = useState([]);
  const [ascendantNftsArray, setAscendatNftsArray] = useState<any>([]);
  const [ascendantLoader, setAscendatLoader] = useState<boolean>(false);
  const [mintingSuccessState, setMintingSuccessState] = useState<string>("");
  const [mintingFailureState, setMintingFailureState] = useState<string>("");
  const [soulzNftsArray, setsoulzNftsArray] = useState<any>([]);
  const [soulZLoader, setSoulzLoader] = useState<boolean>(false);
  const [rerollNftId, setRerollNftId] = useState({
    tokenId: "",
  });
  const [ascendantNftId, setAscendantNftId] = useState<string>("");
  const [rerollNft, setRerollNft] = useState({});
  const [orbzBalance, setOrbzBalance] = useState<number>(0);
  const [traitUpdateObject, setTraitUpdateObject] = useState({
    tokenId: "",
    traits: [],
  });

  const [hasRemainingAscendants, setHasRemainingAscendants] = useState(false);

  const ascendedNftApi = async (
    nextPageParam: string,
    userAccount: string,
    contractAddress: string
  ) => {
    try {
      const ascendetContractAddressInstance = new ethers.Contract(
        contractAddress!,
        erc721ascendatAbi,
        signer
      );

      const response = await axios.get(
        `/api/external?account=${userAccount}&nextPageParam=${nextPageParam}&contractAddress=${contractAddress}`
      );

      let newArrayAscendant: any = [];
      for (const item of response?.data?.data?.result) {
        var json: any = {};
        try {
          const nftData = await ascendetContractAddressInstance.tokenURI(
            item.token_id
          );
          json = await axios.get(nftData);
          newArrayAscendant.push({
            ...item,
            imageUrl: json.data,
          });
        } catch (error) {
          newArrayAscendant.push({
            ...item,
            imageUrl: json.data,
          });
        }
      }

      return {
        ascendants: newArrayAscendant || [],
        total: response?.data?.data?.total || 0,
        cursor: response?.data?.data?.cursor || "",
      };
    } catch (error) {
      console.error(error);
      setAscendatLoader(false);
    }
  };

  const getAscendantWalletNfts = async (account: any) => {
    try {
      setAscendatLoader(true);
      let totalNFTs: number = 0;
      let nextPageParam: any = null;

      do {
        const response = await ascendedNftApi(
          nextPageParam,
          account,
          ascendetContractAddress
        );
        if (response && response.ascendants) {
          setAscendatNftsArray((ascendants: any) => {
            const ascdArray = [...ascendants, ...response.ascendants];

            if (response.total === ascdArray.length) {
              setHasRemainingAscendants(false);
            } else {
              setHasRemainingAscendants(true);
            }

            return [...ascendants, ...response.ascendants];
          });
          totalNFTs = response.total;
          nextPageParam = response.cursor;

          setAscendatLoader(false);
        }
      } while (nextPageParam && nextPageParam != null);
    } catch (error) {
      console.error(error);
      setAscendatLoader(false);
    }
  };

  const gettingSoulZWalletNFTs = async (account: any) => {
    try {
      setSoulzLoader(true);
      let totalNFTsSoulZ: number = 0;
      let nextPageParamSoulZ: any = null;

      do {
        const response = await ascendedNftApi(
          nextPageParamSoulZ,
          account,
          soulZContractAddress!
        );
        if (response && response.ascendants) {
          setsoulzNftsArray((ascendants: any) => {
            return [...ascendants, ...response.ascendants];
          });
          totalNFTsSoulZ = response.total;
          nextPageParamSoulZ = response.cursor;

          setSoulzLoader(false);
        }
      } while (nextPageParamSoulZ && nextPageParamSoulZ != null);
    } catch (error) {
      console.error(error);
      setSoulzLoader(false);
    }
  };

  return (
    <MintAscendedContext.Provider
      value={{
        setMintAscendantImages,
        mintAscendantImages,
        ascendantNftsArray,
        hasRemainingAscendants,
        getAscendantWalletNfts,
        ascendantLoader,
        soulzNftsArray,
        soulZLoader,
        setMintingFailureState,
        mintingFailureState,
        mintingSuccessState,
        setMintingSuccessState,
        gettingSoulZWalletNFTs,
        traitUpdateObject,
        setTraitUpdateObject,
        rerollNftId,
        setRerollNftId,
        orbzBalance,
        setOrbzBalance,
        rerollNft,
        setRerollNft,
        setAscendantNftId,
        ascendantNftId,
      }}
    >
      {children}
    </MintAscendedContext.Provider>
  );
};

export function useMintAscendantData() {
  const context = useContext(MintAscendedContext);
  return context;
}
