import "../styles/globals.css";
import type { AppProps } from "next/app";
import "bootstrap/dist/css/bootstrap.css";
import Web3ContextProvider from "../context/WalletContext";
import ModalProvider from "../context/ModalContext";
import { MintAscendedProvider } from "../context/MintAscendentContext";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Web3ContextProvider>
      <MintAscendedProvider>
        <ModalProvider>
          <Component {...pageProps} />
        </ModalProvider>
      </MintAscendedProvider>
    </Web3ContextProvider>
  );
}

export default MyApp;
