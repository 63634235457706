import {
  InfuraURL,
  EtherScanUrl,
  pinataURL,
  Angel,
  Beast,
  Demon,
  Elf,
  CyborgFemale,
  CyborgMale,
  Skin,
} from "../config/constant";

const getInfuraUrl = (chainId: number) => {
  return InfuraURL[chainId];
};

const getEtherScanUrl = (chainId: number) => {
  return EtherScanUrl[chainId];
};
const toHex = (num: any) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};
const truncateAddress = (address: any) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};
const truncateAddressFull = (address: any) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{9999})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

const extractCider = (uri: string) => {
  if (uri?.startsWith(`${pinataURL}`)) {
    return uri;
  }
  const splitUri = uri?.startsWith("ipfs://")
    ? uri?.split("ipfs://")
    : uri?.split("/ipfs/");
  return `${pinataURL}${splitUri?.length > 1 ? splitUri[1] : ""}`;
};
const supportedTraitSet = (name: string) => {
  if (name.includes(Skin.Angel)) {
    return Angel;
  }

  if (name.includes(Skin.Beast)) {
    return Beast;
  }

  if (name.includes(Skin.Demon)) {
    return Demon;
  }

  if (name.includes(Skin.Elf)) {
    return Elf;
  }

  if (name.includes(Skin.CyborgFemale)) {
    return CyborgFemale;
  }

  if (name.includes(Skin.CyborgMale)) {
    return CyborgMale;
  }
};

export {
  getEtherScanUrl,
  getInfuraUrl,
  supportedTraitSet,
  toHex,
  truncateAddress,
  truncateAddressFull,
  extractCider,
};
