import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "a Asian Hiro";
  src: url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.eot");
  src: url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.eot?#iefix") format("embedded-opentype"),
  url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.woff2") format("woff2"),
  url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.woff") format("woff"),
  url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.ttf") format("truetype"),
  url("/assets/fonts/c92a81f92cf0e17ca9ba8d2779f865cc.svg#a Asian Hiro") format("svg");
  font-display: block;
    }
  * {
    font-family: "a Asian Hiro";
  }
  & ~ script {
    background:#000;
  }
  .gallery-item {
    width:200px;
  }
  .navbar-dark .navbar-nav .nav-link  small {
    display: block;
    font-size: 60%;
    line-height: 0;
    font-weight: 300;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: #f7f7f7 !important;
    padding: 15px 20px !important;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row !important;
    padding-right: 60px !important;
     /* padding: 15px 20px;  */
  }
  
  @media (max-width:992px) {
    .show {
      position: absolute;
      background: #141E1E;
      left: 0;
      right: 0;
      top: 100%;
      padding: 30px;
      height: calc(100vh - 123px);
    }
    .show a {
      text-align: center;
      font-size: 35px;
      padding: 15px 0;
    }
  }
  .banner-text  {
    width:400px;
  }
  @media (max-width:767px) {
    .banner-text  {
      width:300px;
    }
    .banner-text text {
      font-size:35px !important;
    }
  }
  .stick {
    position: relative;
    padding-left: 60px;
  }
  .stick-img {
    position: absolute !important;
    left: 20px  !important;
    top: 10px  !important;
    width:16px  !important;
    height: 100%  !important;
    bottom: 40px  !important; 
  }
  #wrapper {
    height:500px;
    overflow:auto;
  }
  .owl-carousel .owl-stage-outer {
    width:100%;
  }
  .owl-stage {
    transform:none !important;
  }
  .owl-carousel .owl-item {
    opacity:0 !important;
    display:none !important;
    // position: absolute;
    display:block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index:0;
  }
  .owl-carousel .owl-item.active {
    opacity:1 !important;
    z-index:1;
    display:inline !important;
  }
  .owl-carousel .owl-stage-outer,
  .owl-carousel,
  .owl-carousel .owl-stage {
    height: calc(100% + 50px);
  }
  .owl-carousel .owl-item {
    height: 100%;
  }

  .owl-carousel .owl-item .item > span {
    position:static !important;
  }
  body {
    font-family: "a Asian Hiro";
    overflow-x:hidden;
    background-image:url('assets/images/main-bg.png');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  .loader {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:#fff;
    background-image: url(assets/images/main-bg.png);
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    z-index:9;
  }
  .loader-gallery {
    background-image: url(assets/images/soulz-red-bg.png);
  }
  .loader img {
    position:fixed  !important;
    top:50%  !important;
    left:50%  !important;
    transform: translate(-50%, -50%) !important;
    width: 120px !important;
    height: 120px !important;
    min-width: 0 !important;
    min-height: 0 !important;
  }
  .navbar {
    z-index:5 !important;
  }
  iframe {
    pointer-events: none;
  }

  video {
    max-width: 100%;
  }
  .owl-carousel .owl-item img {
    width:100% !important;
    height:100% !important;
  }
  .rotate-animation {
    animation: rotate 1s infinite linear;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  

  @keyframes headerBg {
    0%, 100% {
      background-position: top left;
    }
    20% {
      background-position: right center;
    }
    40% {
      background-position: bottom left;
    }
    60% {
      background-position: bottom right;
    }
    80% {
      background-position: top right;
    }

  }
  .sub-ment {
    position: absolute;
    right: 44px;
    top: 70px;
    background: #141E1E;
    padding: 12px 0 8px;
    min-width: 150px;
  }
  .sub-ment a {
    cursor: pointer;
    padding:8px 20px;
    max-width:50px;
  }
  .nav-link {
    cursor: pointer;
  }
  .footer-social div {
    margin:0 3px;
  }
  #WEB3_CONNECT_MODAL_ID {
    background: #000;
  }
`;

export default GlobalStyle;
